<template>
    <div id="search-page">
        <div class="search-page__search-bar d-flex items-center">
          <b-form-input icon-no-border  placeholder="Digite rodada inicial" v-model="firstRound" class="mr-1 input-rounded-full" icon="icon-search" icon-pack="feather" />
          <b-form-input icon-no-border  placeholder="Digite rodada final" v-model="lastRound" class="ml-1 input-rounded-full" icon="icon-search" icon-pack="feather" />
        </div>
        <div class="search-meta flex flex-wrap justify-between mt-6">
          <b-form-input icon-no-border  placeholder="Digite a porcentagem" v-model="percentage" style="max-width: 180px;" class="mr-1 mt-1 input-rounded-full"/>

          <b-button class="mt-1 mb-1" @click="rushHourCalc()">Calcular</b-button>
          <p class="error">{{errorMessage}}</p>
        </div>

        <!-- SEARCH RESULTS -->
        <div class="mt-4 d-flex">
            <div v-if="queried != null && queried.rush != null" class="w-50 pr-1">
                <b-card class="" title="Hora do rush">
                  <div v-for="winner in queried.rush" class="hour-data">
                    <p>
                      <b>Usuário: </b> {{winner.username}} <b>Vitorias: </b> {{winner.wins}} <b>Cartelas: </b> {{winner.cards}} <b>Invest: </b> {{winner.sum}} 
                      <b v-if="percentage !=null"><br>Reembolsar: {{winner.sum * percentage / 100 | toCurrency}}</b>
                    </p>
                  </div>
                </b-card>
            </div>
            <div v-if="queried != null && queried.rush != null" class="w-50 pl-1">
                <b-card class="" title="Hora do pesadelo">
                  <div v-for="looser in queried.nightmare" class="hour-data">
                    <p>
                      <b>Usuário: </b> {{looser.name}} <b>Cartelas: </b> {{looser.qtd}}  <b>Invest: </b> {{looser.sum}} 
                      <b v-if="percentage !=null"><br>Reembolsar: {{looser.sum * percentage / 100 | toCurrency}}</b>

                    </p>
                  </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import {BCard, BButton, BFormInput} from 'bootstrap-vue'
export default{
  data () {
    return {
      firstRound: null,
      lastRound: null,
      currentPage: 1,
      searchResults: [],
      errorMessage:'',
      queried :null,
      percentage:null
    }
  },
  computed: {},
  methods: {
    searchUser() {
        this.$router.replace({ query: { u: this.searchQuery } })
        this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/userSearch',{
          name: this.searchQuery,
        })
        .then(res => {
            this.searchResults = res.data
            console.log(res.data)
        })
    },
    rushHourCalc(){
      this.firstRound = this.firstRound*1;
      this.lastRound = this.lastRound*1;
      if((this.firstRound == this.lastRound) || this.firstRound == '' || this.lastRound == '' || this.firstRound == null || this.lastRound == null || (this.lastRound < this.firstRound)|| this.firstRound < 0 || this.lastRound < 0){
        this.errorMessage = "Verifique os ids das rodadas";
        return
      }else{
        this.errorMessage = "";
      }
      this.$http.post('/rush-calculate',{
          firstRound: this.firstRound,
          lastRound: this.lastRound,
        })
        .then(res => {
            this.queried = res.data;
        })
    },  
    reload(){
      if(this.$route.query.u){
        this.searchQuery = this.$route.query.u;
        this.searchUser();
      }
    }
  },
  components: {
    BCard,BFormInput,BButton
  },
  created() {
    this.reload();
  }
}
</script>


